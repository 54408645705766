import React, { useRef } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import FeaturedList from "../components/common/featuredlist";
import Review from "../components/common/review";
import VacationExperience from "../components/accommodation/vacationexperience";
// import BgSection from "../components/birding/BgSection";
// import Adventure from "../components/birding/Adventure";
import TowerAdventure from "../components/tower/TowerAdventure";
import TranquilobayDifference from "../components/accommodation/how_tranquilobay_different";
import ScheduleVacation from "../components/birding/Schedule";
import RegenrativeAdventure from "../components/birding/RegenerativeAdventure";
import YourGuides from "../components/common/yourGuides";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import CompaniesGuided from "../components/common/companiesGuided";
import Seo from "../components/seo";
import SliderAndContent from "../components/common/SliderAndContent";
import bg from "../images/bg2.svg";
import useIsVisible from "../hooks/isVisible";

const Birding = ({ data: { sanityBirdingPage, sanitySitesettings } }) => {
  const elemRef = useRef();

  const isVisible = useIsVisible(elemRef);
  let seo;
  let hero;
  let breadcrumb;
  let features;
  let review;
  let guide;
  let bookVacation;
  let panamaVacation;
  let adventure;
  let contentSlider;
  let tranqiulobay;
  let company;
  let birdingAdventure;

  sanityBirdingPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "featureList") {
      features = element;
    } else if (element._type === "reviewSection") {
      review = element;
    } else if (element._type === "bookVacation") {
      bookVacation = element;
    } else if (element._type === "towerAdvantureSection") {
      adventure = element;
    } else if (element._type === "contentAndSlider") {
      contentSlider = element;
    } else if (element._type === "panamaVacation") {
      panamaVacation = element;
    } else if (element._type === "tranqiulobay") {
      tranqiulobay = element;
    } else if (element._type === "companyGuided") {
      company = element;
    } else if (element._type === "guidesSection") {
      guide = element;
    } else if (element._type === "birdingAdventure") {
      birdingAdventure = element;
    }
  });
  return (
    <Layout>
      <Seo data={seo} />
      <Hero
        heroSection={hero}
        h2Size="text-3xl sm:text-[50px] leading-[45px] sm:leading-[60px]"
        h2P="pb-7"
      />
      {sanitySitesettings?.logo && <Logo logo={sanitySitesettings.logo} />}
      {features && (
        <div className="-m-5">
          <FeaturedList data={features} />
        </div>
      )}
      {review && <Review data={review} />}
      <div ref={elemRef}>
        {isVisible && (
          <>
            {bookVacation && (
              <VacationExperience
                data={bookVacation}
                headingSize="text-3xl sm:text-[50px] leading-[63px]"
                show="show-up"
              />
            )}
            {contentSlider && (
              <SliderAndContent
                data={contentSlider}
                sliderId={`birding-1`}
                show="show-up"
              />
            )}
            {adventure && <TowerAdventure data={adventure} show="show-up" />}

            {tranqiulobay && (
              <div
                className="sm:pt-[120px] min-h-[110vh]"
                style={{
                  backgroundImage: `linear-gradient(to bottom,rgba(146, 146, 146, 0.90),rgba(192, 192, 192, 0.90)),url(${bg})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "repeat-x",
                }}
              >
                <TranquilobayDifference
                  data={tranqiulobay}
                  color="text-white"
                  show="show-up"
                />
              </div>
            )}
            {panamaVacation && (
              <ScheduleVacation data={panamaVacation} show="show-up" />
            )}
            {birdingAdventure && (
              <RegenrativeAdventure data={birdingAdventure} show="show-up" />
            )}
            {guide && <YourGuides data={guide} show="show-up" />}

            {breadcrumb && <BreadCrumb path={breadcrumb} show="show-up" />}
            <Instagram />
            {company && (
              <div>
                <CompaniesGuided data={company} show="show-up" />
                <Logo logo={sanitySitesettings?.logo} />
              </div>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Birding;

export const query = graphql`
  query {
    sanityBirdingPage {
      pageBuilder {
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          ctaButton {
            link
            title
            type
            variant
            formId
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }
        ... on SanityFeatureList {
          _type
          featureItems {
            icon
            title
          }
        }
        ... on SanityReviewSection {
          _type
          comment
          subHeading
          subHeading2
          heading
          _rawAuthor
          sectionBg {
            asset {
              url
            }
          }
          ctaButton1 {
            link
            title
            type
            variant
            formId
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
          }
        }
        ... on SanityContentAndSlider {
          _type
          title
          bgRepeat
          bgSize
          direction
          height
          overlay
          size
          textColor
          paragraphTitle
          sectionBG {
            asset {
              url
            }
          }
          ctaButton1 {
            link
            title
            type
            variant
            formId
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
          }
          sliderImages {
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
          _rawContent
          _rawContent2
        }
        ... on SanityBookVacation {
          _type
          heading
          ctaButton1 {
            link
            title
            type
            variant
            formId
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
          }
          sliderImages {
            heading
            subHeading
            sectionBg {
              asset {
                gatsbyImageData(
                  fit: FILLMAX
                  placeholder: BLURRED
                  formats: WEBP
                )
              }
            }
          }
        }

        ... on SanityPanamaVacation {
          _type
          desLink
          description
          heading
          ctaButton1 {
            link
            title
            type
            variant
            formId
          }
          scheduleItem {
            heading
            number
            subHeading
            svg
            itemImg {
              alt
              asset {
                gatsbyImageData(
                  fit: FILLMAX
                  placeholder: BLURRED
                  formats: WEBP
                )
              }
            }
          }
        }
        ... on SanityTowerAdvantureSection {
          _type
          heading
          videoUrl
          youtubeId
          coverImg {
            asset {
              gatsbyImageData(
                fit: FILLMAX
                placeholder: BLURRED
                formats: WEBP
                width: 770
              )
            }
          }
          ctaButton1 {
            link
            title
            type
            variant
            formId
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
          }
        }
        ... on SanityTranqiulobay {
          _type
          _rawContent
          ctaButton1 {
            link
            title
            type
            variant
            formId
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
          }
          sliderItems {
            title
            content
            ctaButton1 {
              link
              title
              type
              variant
              formId
            }
            slideBg {
              asset {
                url
              }
            }
          }
          title
        }
        ... on SanityBirdingAdventure {
          _type
          _rawContent
          heading
          mainlink
          subheading
          svg
          ctaButton1 {
            formId
            link
            title
            type
            variant
            formId
          }
          ctaButton2 {
            formId
            link
            title
            type
            variant
            formId
          }
          ctaButton3 {
            link
            title
            formId
            type
            variant
            formId
          }
          bgImage {
            asset {
              url
            }
          }
          bookItem {
            link
            svg
            title
            _rawDetail
          }
        }
        ... on SanityCompanyGuided {
          _type
          heading
          sectionBg {
            asset {
              url
            }
          }
          list {
            name
          }
        }
        ... on SanityGuidesSection {
          _type
          quote1
          firstQuote
          quote2
          secondQuote
          title
          sliderImage {
            guidance
            leader
            name
            photo {
              asset {
                _id
                metadata {
                  blurHash
                }
              }
              alt
              crop {
                bottom
                left
                right
                top
              }
              hotspot {
                x
                y
              }
            }
          }
        }
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
