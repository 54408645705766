import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { PopupButton } from "@typeform/embed-react";

const Card = ({ item }) => {
  if (
    item?.heading?.toUpperCase()?.includes("REQUEST RESERVATION") ||
    item?.heading?.toUpperCase()?.includes("RESERVATION")
  ) {
    return (
      <div
        className="flex flex-col flex-wrap justify-start col-span-1 row-span-1 rounded-[5px] 
          transition-transform duration-200 ease-linear box-border overflow-hidden cursor-pointer"
        style={{
          boxShadow: "0px 0px 18px rgb(0 0 0 / 50%)",
        }}
      >
        <PopupButton id="pCocsLbQ">
          {/* Counter */}
          <div className="block relative">
            <div className="block w-full py-[10px]">
              <p className="text-2xl leading-9 sm:text-[40px] sm:leading-[52px] font-bold text-[#666666] text-center mb-3">
                {item.number}
              </p>
            </div>
          </div>
          {/* Icon */}
          <div className="block relative">
            <div className="block w-full pb-5">
              <div className="block text-center">
                <div
                  dangerouslySetInnerHTML={{ __html: item.svg }}
                  className="flex justify-center"
                />
              </div>
            </div>
          </div>
          {/* Image Container */}
          <div className="block h-[120px] sm:h-[150px]">
            <GatsbyImage
              image={item?.itemImg?.asset.gatsbyImageData}
              alt={item?.itemImg?.alt}
              className="w-full h-full object-cover object-center"
            />
          </div>
          {/* Text Container */}
          <div className="block relative box-border">
            <div className="relative flex flex-col flex-wrap justify-center py-5 px-[10px]">
              <div className="block w-3/4 mx-auto pt-5">
                <p className="text-[26px] sm:text-3xl text-[#666666] font-bold uppercase text-center mb-3 leading-10 tracking-[2px]">
                  {item.heading}
                </p>
              </div>
              <div className="block w-3/4 mx-auto mb-6">
                <p className="text-center font-bold text-[#323A44]">
                  {item.subHeading}
                </p>
              </div>
            </div>
          </div>
        </PopupButton>
      </div>
    );
  } else {
    return (
      <div
        className="flex flex-col flex-wrap justify-start col-span-1 row-span-1 rounded-[5px] 
    transition-transform duration-200 ease-linear box-border overflow-hidden"
        style={{
          boxShadow: "0px 0px 18px rgb(0 0 0 / 50%)",
        }}
      >
        {/* Counter */}
        <div className="block relative">
          <div className="block w-full py-[10px]">
            <p className="text-2xl leading-9 sm:text-[40px] sm:leading-[52px] font-bold text-[#666666] text-center mb-3">
              {item.number}
            </p>
          </div>
        </div>
        {/* Icon */}
        <div className="block relative">
          <div className="block w-full pb-5">
            <div className="block text-center">
              <div
                dangerouslySetInnerHTML={{ __html: item.svg }}
                className="flex justify-center"
              />
            </div>
          </div>
        </div>
        {/* Image Container */}
        <div className="block h-[120px] sm:h-[150px]">
          <GatsbyImage
            image={item?.itemImg?.asset.gatsbyImageData}
            alt={item?.itemImg?.alt}
            className="w-full h-full object-cover object-center"
          />
        </div>
        {/* Text Container */}
        <div className="block relative box-border">
          <div className="relative flex flex-col flex-wrap justify-center py-5 px-[10px]">
            <div className="block w-3/4 mx-auto pt-5">
              <p className="text-[26px] sm:text-3xl text-[#666666] font-bold uppercase text-center mb-3 leading-10 tracking-[2px]">
                {item.heading}
              </p>
            </div>
            <div className="block w-3/4 mx-auto mb-6">
              <p className="text-center font-bold text-[#323A44]">
                {item.subHeading}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Card;
