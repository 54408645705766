import React, { useState } from "react";
import Button from "../../common/buttons/Button";
import PortableText from "react-portable-text";
import AccommodationSlider from "./AccommodationSlider";

const TranquilobayDifference = ({ data, color, show = "show" }) => {
  const [openSlider, setOpenSlider] = useState(false);
  return (
    <>
      <div className={`max-w-[1140px] p-5 w-full mx-auto min-h-[80vh] ${show}`}>
        {/* Title */}

        <h1
          className={`text-3xl sm:text-[50px] leading-10 sm:leading-[60px] pb-5 text-center ${
            color ? color : "text-[#666666]"
          } mb-3 font-bold tracking-[2px]`}
        >
          {data?.title}
        </h1>

        {/* Paragraph */}
        <div className="w-[90%] mx-auto mb-[25px]">
          <p
            className={`${
              color ? color : "text-[#323A44]"
            } text-left font-bold`}
          >
            {data?._rawContent && (
              <PortableText
                content={data._rawContent}
                className={`${
                  color ? color : "text-[#323A44]"
                } portable-editor block-content max-w-none`}
              />
            )}
            {data?.content && (
              <PortableText
                content={data?.content}
                className={`${
                  color ? color : "text-[#323A44]"
                } portable-editor block-content max-w-none`}
              />
            )}
            <br />
            <br />
            <button
              to="/rates"
              className="text-[#006600] hover:text-[#666666] uppercase cursor-pointer"
              onClick={() => setOpenSlider(true)}
              aria-label="continue"
            >
              {" "}
              Continue Reading
            </button>
          </p>
        </div>
        <div className="w-[95%] mx-auto">
          <div className="flex flex-row flex-wrap max-w-full align-top items-stretch justify-around">
            {/* Button 1 */}
            {data?.ctaButton1 && (
              <div className="w-full sm:w-custom-sm pt-5 pb-0 md:pb-10 lg:w-custom-lg mb-[10px]">
                <Button
                  btnText={data?.ctaButton1?.title}
                  btnLink={data?.ctaButton1?.link}
                  btnType={data?.ctaButton1?.variant}
                  linkType={data?.ctaButton1?.type}
                  formId={data?.ctaButton1?.formId}
                />
              </div>
            )}
            {/* Button 2 */}
            {data?.ctaButton2 && (
              <div className="w-full sm:w-custom-sm pt-5 pb-0 md:pb-10 lg:w-custom-lg mb-[10px]">
                <Button
                  btnText={data?.ctaButton2?.title}
                  btnLink={data?.ctaButton2?.link}
                  btnType={data?.ctaButton2?.variant}
                  linkType={data?.ctaButton2?.type}
                  formId={data?.ctaButton2?.formId}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {data?.sliderItems && openSlider && (
        <AccommodationSlider
          onClose={() => setOpenSlider(false)}
          list={data?.sliderItems}
        />
      )}
    </>
  );
};

export default TranquilobayDifference;
