import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const PrimaryCard = ({ item }) => {
  return (
    <div
      className="flex flex-col flex-wrap justify-start col-span-1 row-span-1 rounded-[5px] 
    transition-transform duration-200 ease-linear box-border overflow-hidden"
      style={{
        boxShadow: "0px 0px 18px rgb(0 0 0 / 50%)",
      }}
    >
      {/* Image Container */}
      {item?.sectionBg?.asset?.gatsbyImageData && (
        <div className="block h-[120px] sm:h-[150px]">
          <GatsbyImage
            image={item?.sectionBg?.asset?.gatsbyImageData}
            alt="Card Image"
            loading="lazy"
            className="w-full h-full"
          />
        </div>
      )}
      {/* Text Container */}

      <div className="py-5 px-[10px]">
        <h1 className="text-[26px] pt-5 sm:text-3xl text-[#666666] font-bold uppercase text-center mb-3">
          {item.heading}
        </h1>

        <h2 className="text-xl text-center pb-5 mb-3 font-semibold tracking-[2px] text-[#666666]">
          {item.subHeading}
        </h2>
      </div>
    </div>
  );
};

export default PrimaryCard;
