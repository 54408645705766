import React, { useEffect } from "react";

export default function AccommodationWrapper({
  children,
  prev,
  next,
  onClose,
}) {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  function handleNext(e) {
    e.stopPropagation();
    next();
  }

  function handlePrev(e) {
    e.stopPropagation();
    prev();
  }
  return (
    <div
      className={`max-w-[100vw] overflow-hidden fixed top-16 sm:top-0 bottom-0 left-0 right-0 z-50 bg-transparent`}
      onClick={onClose}
    >
      <div className="flex items-center justify-center h-full">
        <div
          className="absolute right-5 top-5 cursor-pointer p-1 group bg-black/50 rounded-full hover:bg-white/10 hover:border 
          hover:border-white hover:border-solid transition-all duration-200 w-[40px] h-[40px] flex items-center justify-center z-[9999]"
          onClick={() => onClose()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-12 h-12 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div
          className="absolute left-5 top-1/2 cursor-pointer p-1 group bg-black/50 rounded-full hover:bg-[#006600] hover:scale-95 
          transition-all duration-200 w-[50px] h-[50px] flex items-center justify-center z-[9999]"
          onClick={handlePrev}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-[50px] h-[50px] text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        </div>
        <div className="relative w-full mx-3">{children}</div>
        <div
          className="absolute right-5 top-1/2 cursor-pointer p-1 group bg-black/50 rounded-full hover:bg-[#006600] hover:scale-95 
          transition-all duration-200 w-[50px] h-[50px] flex items-center justify-center z-[9999]"
          onClick={handleNext}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-[50px] h-[50px] text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
